<template>
  <div class="admin-list">
    <h4 class="list-title">{{ $lang.app.quotas }}</h4>
    <div class="admin-navbar justify-between">
      <Search v-if="searchable" @search="search" @reset="fetchResources" />
      <router-link v-if="creatable" :to="`/admin/quotas/create?type=${$route.query.type}`" class="action-button">{{
      $lang.app.create }}
      </router-link>
      <router-link v-if="goronoRegistrationAvailable" to="/admin/application-school" class="action-button">
        {{ $lang.app.application_apply_school }}
      </router-link>
    </div>

    <div class="admin-table">
      <table>
        <thead>
          <tr>
            <th class="key">{{ $lang.app.organization }}</th>
            <th>{{ $lang.app.quota }}</th>
            <th>{{ $lang.app.total_enrolled }}</th>
            <th>{{ $lang.app.privileged_enrolled }}</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="quota in quotas" :key="quota.id">
            <td class="key">{{ $lang.$translate({ kg: quota.organizationNameKG, ru: quota.organizationNameRU }) }}</td>
            <td>{{ quota.total }}</td>
            <td>{{ quota.totalEnrolled }}</td>
            <td>{{ quota.privilegedEnrolled }}</td>
            <td>
              <div class="action-buttons">
                <router-link :to="`/admin/quotas/${quota.id}?type=${$route.query.type }`" class="action-button"
                  :title="$lang.app.view"><i class="bi-info-square"></i></router-link>
                <router-link v-if="editable" :to="`/admin/quotas/${quota.id}/edit?type=${$route.query.type}`"
                  class="action-button" :title="$lang.app.edit"><i class="bi-pencil-square"></i>
                </router-link>
                <button v-if="deletable" class="action-button" :title="$lang.app.delete"
                  @click="deleteRequest(quota.id)"><i class="bi-x-square"></i></button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination :page="page" :links="links" />
    </div>
    <DeleteModal v-if="deleteId" @close="deleteId = null" @delete="deleteResource" />
  </div>
</template>
<script>
import Search from '../../../components/admin/Search'
import DeleteModal from '../../../components/admin/DeleteModal'
import Pagination from '../../../components/admin/Pagination'

export default {
  components: {
    Search,
    DeleteModal,
    Pagination,
  },
  data() {
    return {
      quotas: [],
      page: null,
      links: null,
      deleteId: null,
      isGoronoRegistrationAvailable: null,
    }
  },
  computed: {
    searchable() {
      return this.$store.getters.isAdmin
    },
    creatable() {
      return this.$store.getters.isAdmin /* || (this.$store.getters.isGorono  && this.$route.query.type === 'PRESCHOOL' ) */
    },
    editable() {
      return this.$store.getters.isAdmin || (this.$store.getters.isGorono /* && this.$route.query.type === 'PRESCHOOL' */)
    },
    deletable() {
      return this.$store.getters.isAdmin /* || (this.$store.getters.isGorono  && this.$route.query.type === 'PRESCHOOL' ) */
    },
    goronoRegistrationAvailable() {
      this.fetchGoronoRegistrationAvailability();
      return this.$store.getters.isGorono && this.$route.query.type === 'SCHOOL' && this.isGoronoRegistrationAvailable == true
    }
  },
  watch: {
    '$route'(value, oldValue) {
      if (value.path === oldValue.path) {
        this.fetchResources()
      }
    }
  },
  methods: {
    search(value) {
      this.$axios.get('/quota/search', {
        params: {
          type: this.$route.query.type,
          regionId: value.regionId,
          districtId: value.districtId,
          aymakId: value.aymakId,
          organizationName: value.searchQuery
        }
      }).then(({ data }) => {
        this.quotas = data._embedded ? data._embedded.quotaResourceList : []
        this.page = data.page
        this.links = data._links
      })
    },
    fetchAdminQuotas() {
      this.$axios.get(`/quota/list/${this.$route.query.type}/type`, { params: this.$route.query })
        .then(({ data }) => {
          this.quotas = data._embedded ? data._embedded.quotaResourceList : []
          this.page = data.page
          this.links = data._links
        })
    },
    fetchGoronoRaionoQuotas() {
      this.$axios.get(`/quota/list/person/${this.$store.getters.getPersonId}/`,
        { params: this.$route.query }
      ).then(({ data }) => {
        this.quotas = data._embedded ? data._embedded.quotaResourceList : []
        this.page = data.page
        this.links = data._links
      })
    },
    fetchGoronoRegistrationAvailability() {
      this.$axios.get(`/academic-year/is-gorono-registration-available`)
        .then(response => this.isGoronoRegistrationAvailable = response.data)
    },
    fetchResources() {
      if (this.$store.getters.isAdmin) {
        this.fetchAdminQuotas()
      } else {
        this.fetchGoronoRaionoQuotas()
      }
    },
    deleteRequest(id) {
      this.deleteId = id
    },
    deleteResource() {
      this.$axios.delete(`/quota/${this.deleteId}`).then(() => {
        this.deleteId = null
        this.fetchResources()
        this.$snotify.success(this.$lang.app.resource_deleted_successfully);
      })
    }
  },
  mounted() {
    this.fetchResources()
  }
}
</script>
<style>
@import '../../../assets/styles/admin/list.css';
@import '../../../assets/styles/admin/navbar.css';
@import '../../../assets/styles/admin/table.css';
</style>