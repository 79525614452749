<template>
  <form class="admin-record-search" @submit.prevent="search">
    <div class="search-input-wrapper">
      <div class="reg-step-form-item search-item">
        <label class="recovery-label">{{ $lang.app.region_city }}</label>
        <div class="reg-step-1-select-container search-input-container">
          <i class="bi-chevron-down"></i>
          <select name="regionId"
                 v-model="organizationForm.regionId">
            <option v-for="region in helper.regions" :key="region.id"
                    :value="region.id">{{ region.label }}</option>
          </select>
        </div>
      </div>
      <div class="reg-step-form-item search-item">
        <label class="recovery-label">{{ $lang.app.raion }}</label>
        <div class="reg-step-1-select-container search-input-container">
          <i class="bi-chevron-down"></i>
          <select name="districtId"
                  v-model="organizationForm.districtId">
            <option v-for="district in helper.districts" :key="district.id"
                    :value="district.id">{{ district.label }}</option>
          </select>
        </div>
      </div>
      <div class="reg-step-form-item search-item">
        <label class="recovery-label">{{ $lang.app.aimak }}</label>
        <div class="reg-step-1-select-container search-input-container">
          <i class="bi-chevron-down"></i>
          <select name="districtId"
                  v-model="organizationForm.aymakId">
            <option v-for="aymak in helper.aymaks" :key="aymak.id"
                    :value="aymak.id">{{ aymak.label }}</option>
          </select>
        </div>
      </div>
      <div class="search-input-container">
        <input name="searchQuery" type="text"
               placeholder="Поиск"
               v-model="organizationForm.searchQuery"/>
      </div>
    </div>
    <button class="search-button" type="submit">Поиск</button>
  </form>
</template>
<script>
export default {
  data() {
    return {
      organizationForm: {
        regionId: null,
        districtId: null,
        aymakId: null,
        searchQuery: '',
      },
      helper: {
        regions: [],
        districts: [],
        aymaks: [],
      },
    }
  },
  created() {
    this.fetchChild(4948);
  },
  watch: {
    'organizationForm.type'() {
      this.organizationForm.regionId = null;
      this.organizationForm.districtId = null;
      this.helper.districts = [];
    },
    'organizationForm.regionId'(value) {
      this.organizationForm.districtId = null;
      this.organizationForm.aymakId = null;
      this.helper.districts = [];
      this.helper.aymaks = [];
      if(value) {
        this.fetchChild(value)
        this.search()
      }
    },
    'organizationForm.districtId'(value) {
      this.organizationForm.aymakId = null;
      if(value) {
        this.fetchChild(value)
        this.search()
      }
    },
    'organizationForm.aymakId'(value) {
      if(value) {
        this.search()
      }
    },
  },
  methods: {
    fetchChild(id) {
      return this.$axios.get(`/address-service/children?parent=${id}`)
          .then(({data}) => {
            if (data.length > 0) {
              switch (data[0].type) {
                case 5:
                case 2:
                  this.helper.regions = this.getParsed(data)
                  break;
                case 3:
                case 6:
                  this.helper.districts = this.getParsed(data);
                  break;
                case 4:
                case 7:
                case 101:
                  this.helper.aymaks = this.getParsed(data)
                  break;
              }
            }
          })
    },
    getParsed(data) {
      return data.map(item => {
        return {
          id: item.id,
          label: this.$lang.$translate({kg: item.nameKg + ' ' + item.typeKgShort, ru: item.nameRu})
        }
      })
    },

    search() {
      if(this.organizationForm) {
        this.$emit('search', this.organizationForm)
      } else {
        this.$emit('reset')
      }
    }
  }
}
</script>
<style>
@import '../../assets/styles/form.css';
@import '../../assets/styles/tabs.css';
.admin-record-search {
  display: flex;
  align-items: end;
}
.search-input-wrapper {
  margin: 0 10px;
  display: flex;
  align-items: end;
}
.search-input-container {
  width: 100%;
  height: 46px;
  line-height: 46px;
  border: 1px solid #74b65f;
  position: relative;
}
.search-input-container input {
  width: 100%;
  height: 100%;
  border: none;
  margin: 0;
  vertical-align: middle;
  outline: none;
  display: block;
  padding: 0 15px;
  font-size: 20px;
  background: #fff;
}
.search-item {
  width: 300px;
  flex-shrink: 0;
  margin-right: 12px;
  margin-bottom: 0;
}
.search-input-container input::placeholder {
  font-size: 16px;
}
.search-button {
  display: inline-block;
  height: 46px;
  line-height: 46px;
  border: none;
  background: linear-gradient(to bottom, #63b8d8 0%, #357cb6 100%);
  border-radius: 4px;
  overflow: hidden;
  text-align: center;
  color: #fff;
  font-weight: bold;
  padding: 0 24px;
  transition: 0.5s;
  box-shadow: 0 0 10px 0 rgb(0 0 0 / 15%);
  text-decoration: none;
  font-size: 18px;
  margin-left: 10px;
  margin-right: 10px;
}
.search-button:hover {
  background: linear-gradient(to bottom, #74e8fc 0%, #375fb7 100%);
}
</style>